
const firebaseConfig = {
    apiKey: "AIzaSyBrQYWrKrP37lCB9PPwACSoniUGjLM-8Ag",
    authDomain: "realprotennis-91f6d.firebaseapp.com",
    databaseURL: "https://realprotennis-91f6d.firebaseio.com",
    projectId: "realprotennis-91f6d",
    storageBucket: "realprotennis-91f6d.appspot.com",
    messagingSenderId: "624858516486",
    appId: "1:624858516486:web:113f4632f8036dbb134787",
    measurementId: "G-GJWDBSHWP1"
  };

export default firebaseConfig;