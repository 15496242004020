import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import { CountryDropdown, RegionDropdown} from 'react-country-region-selector';
import fireconfig from '../assets/utils/firebase-config';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/storage';
import ProgressBar from 'react-bootstrap/ProgressBar';
import createDbRecord from '../assets/utils/createDbRecord';
import sendEmail from '../assets/utils/sendEmail';

const UploadForm = () => {

      const fileInput = useRef(null);
      const [progress, setProgress] = useState(0);
      const [uploadInProgress, isUploadInProgress] = useState('false');

       const validate = values => {
           const errors = {};
           if (!values.name) {
               errors.name = 'Required';
           } else if (values.name.length > 15) {
               errors.name = 'Must be 15 characters or less';
           }

           if (!values.city) {
               errors.city = 'Required';
           } else if (values.city.length > 15) {
               errors.city = 'Must be 15 characters or less';
           }

           if (!values.email) {
               errors.email = 'Required';
           } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
               errors.email = 'Invalid email address';
           }

           return errors;
       };

       const formik = useFormik({
            initialValues: {
                email: '',
                name: '',
                ustalevel:'1.0',
                country: 'United States',
                state: 'Illinois',
                city: '',
                comments: '',
                aboutus: 'instagram',
                fileUpload:'',
            },
            validate,
           onSubmit: values => {
             //  values.preventDefault();
             //  alert(JSON.stringify(values, null, 2));
               
                firebase.initializeApp({
                    apiKey: fireconfig.apiKey,
                    authDomain: fireconfig.authDomain,
                    databaseURL: fireconfig.databaseURL,
                    projectId: fireconfig.projectId,
                    storageBucket: fireconfig.storageBucket,
                    messagingSenderId: fireconfig.messagingSenderId,
                    appId: fireconfig.appId,
                    measurementId: fireconfig.measurementId
               });
              
               firebase.analytics();

               var firedb = firebase.firestore();
               var storage = firebase.storage().ref();
            
               var file = fileInput.current.files[0];
               
              // // console.log("File name " + file);

                // Create the file metadata
               // var metadata = {
                 //   contentType: 'video/mp4'
               // };

           var date = new Date();
           // console.log("Uploading File..");

           // Upload file and metadata to the object 'videos/filename'
            var uploadTask = storage.child('videos/' + values.email + '-' + date.valueOf() + '-' + file.name ).put(file);//, metadata);

           // Listen for state changes, errors, and completion of the upload.
           uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
               function (snapshot) {
                   // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded 
                    setProgress(Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100))
                    isUploadInProgress('true');
                    //// console.log('Upload is ' + progress + '% done');
                   switch (snapshot.state) {
                       case firebase.storage.TaskState.PAUSED: // or 'paused'
                           // console.log('Upload is paused');
                           break;
                       case firebase.storage.TaskState.RUNNING: // or 'running'
                           // console.log('Upload is running');
                           break;
                   }
               }, function (error) {
                   isUploadInProgress('error');
                   // A full list of error codes is available at
                   // https://firebase.google.com/docs/storage/web/handle-errors
                   switch (error.code) {
                       case 'storage/unauthorized':
                           // User doesn't have permission to access the object
                           break;
                       case 'storage/canceled':
                           // User canceled the upload
                           break;
                        case 'storage/unknown':
                          // Unknown error occurred, inspect error.serverResponse
                           break;
                    }
}, function() {
    // Upload completed successfully, now we can get the download URL
       uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
        // console.log('File available at', downloadURL);
         isUploadInProgress('true');
        // console.log("Creating DB record");
        createDbRecord(firedb, values, date, downloadURL)
       })
        .then(result => {           
            // console.log("Sending Email...got DB ID " + result);
            
            sendEmail(values)
            .then(result => {
                isUploadInProgress('done');
                fileInput.current.value='';
                formik.resetForm();
                // console.log("Email Sent");
            })
            .catch(error => isUploadInProgress('error')) 
        });
    })
   
    }
}); 
      //Updating Progress Bar and Redirect
        let progressBar;

        if (uploadInProgress === 'true') {
            progressBar = <ProgressBar animated now={progress} label={`${progress}%`} min="0" max="100" />
        } else if (uploadInProgress === 'done'){
            progressBar = 
            <div className="text-gray">
                <ProgressBar variant="success" now={progress} label="Done" min="0" max="100" />
                 <p> Thank you for contacting us, we will get back to you soon</p>
            </div>
        } 
        else if (uploadInProgress === 'error') {
            progressBar = <div><p>Oops...Something went wrong, please try again</p></div>
        } 

       return (
               <div className="container">
                   <div className="row justify-content-center">
                       <div className="col-lg-8 text-center">
                           <h2 className="mt-0 text-teal">Send us your Video</h2>
                           <hr className="divider my-4" />
                       <p className="text-bold mb-5 text-gray">Fill out the form, we will get back to you soon</p>
                       </div>
                   </div>
             <form onSubmit={formik.handleSubmit} className="col-lg-6 offset-lg-3">
                <div className="form-group text-gray">
                     <label htmlFor="email">Email Address</label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            className="form-control"
                        />
                        <small id="emailHelp" className="form-text text-muted">We&apos;ll never share your email with anyone else.</small>
                        {formik.errors.email ? <div>{formik.errors.email}</div> : null}
                   </div>
                   <div className="form-group text-gray">
                        <label htmlFor="name">Your Name</label>
                        <input
                            id="name"
                            name="name"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            className="form-control"
                        />
                        {formik.errors.name ? <div>{formik.errors.name}</div> : null}
                 </div>   
                   <div className="form-group text-gray">
                       <label htmlFor="ustalevel">USTA Level (take your best guess)</label>
                       <select className="form-control" id="ustalevel" onChange={formik.handleChange} value={formik.values.ustalevel}>
                           <option value="1.0">1.0 - Just Started Playing Tennis</option>
                           <option value="1.5">1.5 - Limited Experience</option>
                           <option value="2.0">2.0 - Familiar with basics</option>
                           <option value="2.5">2.5 - Need to improve technique</option>
                           <option value="3.0">3.0 - Need to improve consistency</option>
                           <option value="3.5">3.5 - Need Physical and Mental strength</option>
                           <option value="4.0">4.0 - I need Practice Partner to Improve</option>
                        </select>
                </div>
                   <div className="form-group text-gray">    
                       <label htmlFor="country">Country</label>
                       <CountryDropdown className="form-control" id="country" name="country"
                        value={formik.values.country}
                        onChange={(_, e) => formik.handleChange(e)}/>
                       <label htmlFor="state">State or Region</label>
                </div> 
                   <div className="form-group text-gray">
                       <RegionDropdown className="form-control" name="state" country={formik.values.country} value={formik.values.state}
                           onChange={(_, e) => formik.handleChange(e)}/>
                </div>
                   <div className="form-group text-gray">
                        <label htmlFor="city">City</label>
                        <input
                            id="city"
                            name="city"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.city}
                            className="form-control"
                        />
                        {formik.errors.city ? <div>{formik.errors.city}</div> : null}
                </div>
                   <div className="form-group text-gray">
                        <label htmlFor="comments">Anything you want to tell us about your game or technique, what are you looking for?</label>
                        <textarea
                            id="comments"
                            name="comments"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.comments}
                            className="form-control"
                            rows="3"
                        />
                </div>
                   <div className="form-group text-gray">
                        <label htmlFor="aboutus">How did you hear about us?</label>
                       <select className="form-control" id="aboutus" onChange={formik.handleChange} value={formik.values.aboutus}>
                                <option value="instagram">Instagram</option>
                                <option value="google">Google</option>
                                <option value="facebook">Facebook</option>
                                <option value="google">YouTube</option>
                                <option value="friend">Refer by a Friend</option>
                                <option value="other">Other</option>
                        </select>
                </div>
                <div className="form-group">
                   <div className="form-group text-gray">
                       <label htmlFor="fileUpload">Upload your Video only</label>
                           <input type="file" className="form-control-file" id="fileUpload" ref={fileInput} onChange={formik.handleChange} accept="video/*"/>
                </div>
                </div>
               <div className="form-group">
                       <button type="submit" className="btn btn-primary btn-lg btn-block">Submit</button>
                </div>
               <div className="form-group">
                         {progressBar}
               </div>          
        </form>
        </div>
        );
    }

export default UploadForm;