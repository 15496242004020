import React from 'react';

export default function Services() {

    return (

    <section className="page-section" id="services">
        <div className="container">
           <h2 className="text-center mt-0 text-primary">At Your Service</h2>
            <hr className="divider my-4" />
            <div className="row">
                <div className="col-lg-3 col-md-6 text-center text-gray">
                  <a href="/upload">
                    <div className="mt-5">
                        <i className="fas fa-4x fa-video text-red mb-4"></i>
                        <h3 className="h4 mb-2">Video Analysis</h3>
                        <p className="text-muted mb-0">Send us your video, we will provide video feedback on how you can fix your technique</p>
                    </div>
                    </a>
                </div>
                <div className="col-lg-3 col-md-6 text-center text-gray">
                   <a href="/contact">    
                    <div className="mt-5">
                        <i className="fas fa-4x fa-users text-red mb-4"></i>
                        <h3 className="h4 mb-2">Private Lessons</h3>
                        <p className="text-muted mb-0">Get one on one with us, have fun and improve your game</p>
                    </div>
                    </a>
                </div>
                <div className="col-lg-3 col-md-6 text-center text-gray">
                        <a href="/contact">              
                    <div className="mt-5">
                        <i className="fas fa-4x fa-gem text-red mb-4"></i>
                        <h3 className="h4 mb-2">Racquet Stringing</h3>
                        <p className="text-muted mb-0">Get a new set of fresh strings, first labor is on us</p>
                    </div>
                    </a>
                </div>
                <div className="col-lg-3 col-md-6 text-center text-gray">
                 <a href="#">      
                    <div className="mt-5">
                        <i className="fas fa-4x fa-bullseye text-red mb-4"></i>
                        <h3 className="h4 mb-2">Drills</h3>
                        <p className="text-muted mb-0">Get Tips and Strategies to Beat your next opponent</p>
                    </div>
                 </a>   
                </div>
            </div>
        </div>
    </section>
    )
}

