import React, {useState} from 'react';
import { useFormik } from 'formik';
import sendEmail from '../assets/utils/sendEmail';

export default function ContactForm() {

    const [submitForm, isSubmittingForm] = useState('false');

    const validate = values => {
        const errors = {};

        if (!values.name) {
            errors.name = 'Required';
        } else if (values.name.length > 15) {
            errors.name = 'Must be 15 characters or less';
        }

        if (!values.email) {
            errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }

        if (!values.comments) {
            errors.comments = 'Required';
        } 

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            name: '',
            comments: ''
        },
        validate,
        onSubmit: values => {
             //alert(JSON.stringify(values, null, 2));
            isSubmittingForm('true');
            sendEmail(values)
                .then(response => {
                    // console.log("Email Sent "+ response);
                    isSubmittingForm('done');
                    formik.resetForm();   
                })
                .catch(error => isSubmittingForm('error'));
        }
    }); 

    let progressBar;

    if (submitForm === 'true') {
        progressBar = <div className="d-flex justify-content-center">
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Sending...</span>
                      </div>
                      </div>
    } else if (submitForm === 'done') {
        progressBar = <div>
                        <p> Thank you for contacting us, we will get back to you soon</p>
                     </div>   
    }
    else if (submitForm === 'error') {
        progressBar = <div><p>Oops...Something went wrong, please try again</p></div>
    } 

    return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 text-center">
                        <h2 className="mt-0 text-teal">Let&apos;s Get In Touch!</h2>
                        <hr className="divider my-4" />
                        <p className="text-bold text-gray">Are you ready to take your game to next level?</p>
                        <p className="text-bold text-gray">Submit a form below and we will get back to you as soon as possible!</p>
                        <p className="text-bold text-gray">Note: Currently we offer private lessons in Aurora/Naperville, IL, USA only</p>
                        <hr className="divider my-4" />
                    </div>
                </div>
                <div>
                    <form className="col-lg-6 offset-lg-3 " onSubmit={formik.handleSubmit}>
                        <div className="form-group text-gray">
                            <label htmlFor="email">Email Address</label>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                className="form-control"
                            />
                            <small id="emailHelp" className="form-text text-muted">We&apos;ll never share your email with anyone else.</small>
                            {formik.errors.email ? <div>{formik.errors.email}</div> : null}
                        </div>
                        <div className="form-group text-gray">
                            <label htmlFor="name">Your Name</label>
                            <input
                                id="name"
                                name="name"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                className="form-control"
                            />
                            {formik.errors.name ? <div>{formik.errors.name}</div> : null}
                        </div>
                        <div className="form-group text-gray">
                            <label htmlFor="comments">How can we help?</label>
                            <textarea
                                id="comments"
                                name="comments"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.comments}
                                className="form-control"
                                rows="3"
                            />
                            {formik.errors.comments ? <div>{formik.errors.comments}</div> : null}
                         </div>   
                        <div className="form-group text-gray">
                            <button type="submit" className="btn btn-primary btn-lg btn-block">Submit</button>
                       </div>
                        <div className="form-group text-gray">
                          {progressBar}
                        </div>
                        </form>
                    </div>
                </div>
    )
}
