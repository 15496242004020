import React from 'react';
import rpt from '../assets/img/rpt-zee-full-logo.png';


export default function Navigation() {
    return (
        <nav className="navbar navbar-dark bg-dark navbar-expand-lg py-3 fixed-top" id="mainNav">
                <div className="container">
                    <a className="navbar-brand js-scroll-trigger text-teal" href="/">
                    <img src={rpt} width="145" height="100" className="d-inline-block align-top" alt="" loading="lazy" />
                    </a>
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ml-auto my-2 my-lg-0">
                        <li className="nav-item"><a className="nav-link js-scroll-trigger" href="/#about">About</a></li>
                        <li className="nav-item"><a className="nav-link js-scroll-trigger" href="/#services">Services</a></li>
                        <li className="nav-item"><a className="nav-link js-scroll-trigger" href="/upload">Free Video Analysis</a></li>
                        <li className="nav-item"><a className="nav-link js-scroll-trigger" href="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
    )
}
