import React from 'react';
import slide1 from '../assets/img/masterheader.jpg';
import slide2 from '../assets/img/rpt-logo.jpg';
import slide3 from '../assets/img/realprotennis-cover.jpg';


export default function Header() {

    return (
        <div id="carousel" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators">
                <li data-target="#carousel" data-slide-to="0" className="active"></li>
                <li data-target="#carousel" data-slide-to="1"></li>
                <li data-target="#carousel" data-slide-to="2"></li>
            </ol>
            <div className="carousel-inner bg-light" role="listbox">
                <div className="carousel-item active">
                    <div className="d-flex flex-column align-items-center justify-content-center min-vh-100 text-center">
                        <h2>TIRED of BRAIN-DEAD tennis?</h2>
                        <p>Losing to weaker opponents?</p>
                        <a className="btn btn-secondary btn-lg" href="/upload">Get Started!</a>
                    </div>
                </div>  
                <div className="carousel-item">
                    <div className="d-flex flex-column align-items-center justify-content-center min-vh-100 text-center">
                        <h2>THE LINES ARE SAME</h2>
                        <p>Wheather you are playing in your backyard or Arthur Ashe Stadium</p>
                        <a className="btn btn-secondary btn-lg" href="/contact">Take Your Game to Next Level</a>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className="d-flex flex-column align-items-center justify-content-center min-vh-100 text-center">
                        <h2>GET FREE VIDEO ANALYSIS</h2>
                        <p>Yes it&apos;s FREE No strings attached!</p>
                        <a className="btn btn-secondary btn-lg" href="/upload">Send Us Your Video</a>
                    </div>
                </div>
                <div className="carousel-item">
                    <div className="d-flex flex-column align-items-center justify-content-center min-vh-100 text-center">
                        <h2>PRIVATE LESSONS OR RACQUET STRINGING?</h2>
                        <p>Need a fresh prespective?</p>
                        <a className="btn btn-secondary btn-lg" href="#services">Contact Us</a>
                    </div>
                </div>
            </div>  
            <a className="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carousel" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
            </a>
        </div>
    )
} 