import React from 'react';

export default function About() {

    return (

    <section className="page-section bg-secondary" id="about">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8 text-center">
                        <h2 className="text-white mt-0 text-teal">We&apos;ve got what you need!</h2>
                    <hr className="divider light my-4" />
                        <p className="text-white mb-4">Get Free video analysis from our experts and improve your game. </p>
                    <p className="text-white mb-4">Our coaches have worked with 5 years old kids to never grown up adults.</p>
                    <a className="btn btn-primary btn-lg" href="#services">Get Started!</a>
                </div>
            </div>
        </div>
    </section>
    )
}