import awsconfig from './aws-config';
import axios from 'axios';

export default function sendEmail(values) {

    return new Promise(function (resolve, reject)
    {
        var data = JSON.stringify({
            "email": values.email,
            "name": values.name,
            "comments": values.comments ? values.comments: "No Comments",
            "submissionType": values.ustalevel ? "Video Analysis" : "Contact Form",
        });

        var config = {
            method: 'post',
            url: awsconfig.SesEmailAPI,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
               // // console.log(JSON.stringify(response.data));
               return resolve(response.data);
            })
            .catch(function (error) {
               // // console.log(error);
               return reject(error);
            });
    })
}

