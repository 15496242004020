import React from 'react';
import ContactForm from '../components/ContactForm';
import Header from '../components/Header';
import About from '../components/About';
import Services from '../components/Services';
import Instagram from '../components/Instragram';

export default function Home() {

    return (
        <div className='container-fluid'>
            <Header />
            <About />
            <Services />
            <ContactForm />
        </div>
    )
}
