import React from 'react';
import Home from './pages/Home';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NotFound from "./pages/NotFound";
import Shop from './pages/Shop';
import UploadForm from './pages/UploadForm';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Contact from './pages/Contact';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-180864175-1');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <BrowserRouter>

    <div className='container-fluid'>
      <Navigation/>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/shop" component={Shop} />
          <Route path="/upload" component={UploadForm} />
          <Route path="/contact" component={Contact} />
          <Route component={NotFound} />
        </Switch>
        <Footer/>
     </div>
    </BrowserRouter>
  );
}

export default App;
