
export default function createRecord (firedb, values, date, downloadURL) {

    return new Promise(function (resolve, reject) {

        firedb.collection("messages").add({
            datetime: date.toUTCString(),
            utcdate: date.valueOf(),
            email: values.email,
            name: values.name,
            ustalevel: values.ustalevel,
            country: values.country,
            state: values.state,
            city: values.city,
            comments: values.comments,
            aboutus: values.aboutus,
            fileURL: downloadURL,
            responded: 'N',
            responseDate: ''
        })
        .then(result => resolve(result.id))
        .catch(error => reject(error));
    })
}  