import React from 'react'


export default function Footer(){
    return (
        <footer className="bg-dark py-5">
            <div className="container"><div className="small text-center text-muted">Copyright © 2020 - RealProTennis.com</div></div>
        </footer>
    )
}
